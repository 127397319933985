import React, { useEffect, useState } from "react";
import "./GlossaryModal.scss";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as GlossaryIcon } from "../../assets/glossary.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/chevronRight.svg";
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import DoneIcon from "@mui/icons-material/Done";
import { Button, TextField, Tooltip, styled } from "@mui/material";
import { useSelector } from "react-redux";
import {
  addGlossarytoTheBook,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import { getUserNameFromEmail } from "../../utils/translationUtil";

const GlossaryModal = ({
  onClose,
  // englishWord,
  chapter,
  glossaryData,
  selectedEnglishWord,
  glossarySearchInput,
  setGlossarySearchInput
}) => {
  // console.log("englishWord",englishWord)
  const [dutchVarients, setDutchVarients] = useState([]);
  const [inputVariant, setInputVariant] = useState("");
  const { loggedInUserEmail } = useSelector((state) => state.main);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVariantForEdit, setSelectedVariantForEdit] = useState("");
  const [englishWord, setEnglishWord] = useState("");
  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [englishWordInputFeild, setEnglishWordInputFeild] = useState("")

  const handleInputChange = (e) => {
    console.log(e.target.value, "value");
    // setShowInputFeildError(false);
    setEnglishWordInputFeild(e.target.value);
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  useEffect(() => {
    const glossaryElem = document.querySelector(".glossaryContainer");
    glossaryElem.style.zIndex = "10";
    if (selectedEnglishWord) {
      const glossaryItem = glossaryData.find(
        (glossary) => glossary.english_word === selectedEnglishWord
      );
      setDutchVarients(glossaryItem.dutch_word_array);
    }
    if(glossarySearchInput){
      setEnglishWordInputFeild(glossarySearchInput)
    }
    return () => {
      const glossaryElem = document.querySelector(".glossaryContainer");
      glossaryElem.style.zIndex = "0";
    };
  }, []);

  const addDutchVariants = () => {
    if (!inputVariant) {
      toast(`Input feild cannot be empty`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }

    let isThisVariantAlreadyExists = dutchVarients.find(
      (varient) =>
        varient?.replacement_dutch_word?.toLowerCase() ===
        inputVariant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }

    if (!isThisVariantAlreadyExists || isThisVariantAlreadyExists === -1) {
      const newVariant = {
        metadata_storage: {
          "creation date": new Date(),
          creation_user: loggedInUserEmail,
          application_history: [],
        },
        replacement_dutch_word: inputVariant.trim(),
      };
      setDutchVarients((prev) => [...prev, newVariant]);
      setShowVariantInputFeild(false);
      setInputVariant("");
    }
  };

  const handleDeleteVarient = (variant) => {
    setDutchVarients((prev) =>
      prev.filter(
        (dutchVariant) => dutchVariant.replacement_dutch_word !== variant
      )
    );
  };

  const handleAddToTheGlossary = async (type) => {
    if (!dutchVarients.length) return;
    let toSearchEnglishWord = selectedEnglishWord || englishWord;
    const isCurrentGlossaryExists = glossaryData.find(
      (glossary) => glossary?.english_word?.toLowerCase() === toSearchEnglishWord?.toLowerCase()
    );

    console.log("isCurrentGlossaryExists", isCurrentGlossaryExists);
    if (
      (!isCurrentGlossaryExists || isCurrentGlossaryExists === -1) &&
      !selectedEnglishWord
    ) {
      setIsLoading(true);
      const glossaryPayload = {
        unique_string: chapter.translation_id,
        glossary_item: {
          author: chapter.book_info.author_name,
          language: "dutch",
          genre: chapter.book_info.genre,
          english_word: englishWord,
          dutch_word_array: dutchVarients,
          glossary_item_scope: "Book",
          application_scope: "book_level",
          chapter_numbers: [],
        },
      };
      await addGlossarytoTheBook(glossaryPayload);
      toast(`Glossary successfully added to the book`, {
        type: "success",
        autoClose: 3000,
      });
      onClose();
      setIsLoading(false);
      return;
    }

    if (selectedEnglishWord || isCurrentGlossaryExists) {
      setIsLoading(true);
      const glossaryPayload = {
        author: chapter.book_info.author_name,
        language: "dutch",
        genre: chapter.book_info.genre,
        english_word: isCurrentGlossaryExists?.english_word,
        dutch_word_array: dutchVarients,
        glossary_item_scope: "Book",
        application_scope: "book_level",
        chapter_numbers: [],
        is_active: true,
        is_part_of_collection: false,
        application_history: null,
      };

      await updateGlossarytoTheBook(
        glossaryPayload,
        isCurrentGlossaryExists.id
      );
      toast(`Glossary successfully added to the book`, {
        type: "success",
        autoClose: 3000,
      });
      onClose();

      setIsLoading(false);
    }
  };

  const handleUpdateVariant = (variant) => {
    let isVariantAlreadyExist = false;
    const updatedDutchVariants = dutchVarients.map((dutchVariant) => {
      if (
        inputVariant?.trim()?.toLowerCase() ===
        dutchVariant?.replacement_dutch_word?.toLowerCase()
      ) {
        toast(`Variant already exists`, {
          type: "warning",
          autoClose: 3000,
        });
        isVariantAlreadyExist = true;
      }

      if (dutchVariant.replacement_dutch_word === variant) {
        dutchVariant.replacement_dutch_word = inputVariant.trim();
      }
      return dutchVariant;
    });

    if (isVariantAlreadyExist) return;
    setDutchVarients(updatedDutchVariants);
    setInputVariant("");
    setShowVariantInputFeild(false);
    setSelectedVariantForEdit("");
  };

  console.log({englishWord, selectedEnglishWord});
  

  return (
    <div className="glossary-overlay">
      <ToastContainer hideProgressBar={true} />
      <div className="glossary-box flex  relative flex-col gap-2 w-[687px] h-[80vh]">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <GlossaryIcon />{" "}
            <span className="text-sm text-[#1E1C80]">Add to Glossary</span>
          </div>
          <div>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => {
                if (isLoading) return;
                onClose();
              }}
            />
          </div>
        </div>
        {
          // <div className="flex flex-col gap-1">
          //   <span className="text-[#5F6368] text-[10px]">
          //     {selectedEnglishWord || englishWord
          //       ? "Entered Sentence / Word"
          //       : "Sentence / Word"}
          //   </span>
          //   {selectedEnglishWord  || englishWord? (
          //     <span className="text-[#000] -mt-0 text-[12px]">
          //       {selectedEnglishWord || englishWord}
          //     </span>
          //   ) : (
          //     <div style={{borderBottom:"1px solid #1E1C80"}} className="flex pb-1 w-60 items-center">
          //       <input
          //         value={englishWordInputFeild}
          //         autoComplete="off"
          //         // inputProps={{
          //         //   autoComplete: "off",
          //         // }}
          //         // error={showInputFeildError}
          //         // helperText={
          //         //   showInputFeildError && "Please enter english word/sentence first"
          //         // }
          //         // id="standard-basic"
          //         // variant="standard"
          //         // sx={{
          //         //   "& .css-1nhz38b-MuiInputBase-input-MuiInput-input": {
          //         //     fontSize: "14px",
          //         //     border:"none"
          //         //   },
          //         // }}
          //         maxLength={100}
          //         onChange={handleInputChange}
          //         placeholder="Enter english word / sentence here .."
          //         className="text-[#000] w-full   rounded-sm  border-none outline-none  text-[12px]"
          //       />
          //       {englishWordInputFeild && <div className="flex  gap-2 ">
          //         {/* <CloseIcon
          //           onClick={() => {
                     
          //           }}
          //           className="cursor-pointer w-[18px]  h-[18px] text-[#FF3B30]"
          //         /> */}
          //         <DoneIcon
          //           onClick={() => {
          //             const isCurrentGlossaryExists = glossaryData.find(
          //               (glossary) => glossary?.english_word?.toLowerCase() === englishWordInputFeild?.trim()?.toLowerCase()
          //             );
                     
          //             if(isCurrentGlossaryExists){
          //               toast(`English sentence / word already exists`, {
          //                 type: "warning",
          //                 autoClose: 3000,
          //               });
          //               return ;
          //             }
                      
                      
          //             setEnglishWord(englishWordInputFeild.trim())
          //           }}
          //           style={{
          //             color: "#52C41A",
          //             cursor: "pointer",
          //             width: "18px",
          //             height: "18px",
          //           }}
          //         />
          //       </div>}
          //     </div>
          //   )}
          // </div>
        }
        <div
          style={{ borderBottom: "1px solid #BFBFBF" }}
          className="flex items-center pb-2  gap-5 w-full"
        >
          <div className="flex justify-between w-1/2 text-sm text-[#1E1C80] bg-[#F1F3F4] items-center py-2 pr-2 pl-4">
            English Glossary{" "}
          </div>
          <div className="flex justify-between w-1/2 text-sm text-[#1E1C80]  bg-[#F1F3F4] items-center py-2 pr-2 pl-4">
            Dutch Variants
          </div>
        </div>
        <div className="flex items-start gap-5  w-full">
          {(englishWord || selectedEnglishWord) ? (
            <>
              <div className="py-2 pr-2 h-full text-xs w-1/2 bg-[#F1F3F4] pl-4">
                {selectedEnglishWord || englishWord}
              </div>
              <div className="flex w-1/2 overflow-y-scroll hideSourceScrollBar  gap-2 flex-col"
                style={{maxHeight: "calc(100dvh - 20vh - 161px - 50px)",
                }}
              >
                {dutchVarients.map((varient) => {
                  return (
                    <div className="py-2 px-2 text-xs flex justify-between gap-1 flex-col  bg-[#F1F3F4] ">
                      {!showVariantInputFeild ||
                      selectedVariantForEdit !==
                        varient.replacement_dutch_word ? (
                        <>
                          {varient.replacement_dutch_word}
                          <div className="flex items-end  justify-end gap-2">
                            <CustomTooltip
                              sx={{
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "black", // Change to your desired color
                                  color: "white", // Optional: Change text color
                                },
                              }}
                              placement="top-end"
                              arrow
                              title={
                                varient?.metadata_storage?.creation_user &&
                                `Added by: ${getUserNameFromEmail(
                                  varient?.metadata_storage?.creation_user
                                )}`
                              }
                            >
                              <GlossaryInfoIcon className="cursor-pointer w-[14px] h-[14px]" />
                            </CustomTooltip>
                            <GlossaryDeleteIcon
                              onClick={() =>
                                handleDeleteVarient(
                                  varient.replacement_dutch_word
                                )
                              }
                              className="cursor-pointer w-[14px] h-[14px]"
                            />
                            <GlossaryEditIcon
                              onClick={() => {
                                setShowVariantInputFeild(true);
                                setInputVariant(varient.replacement_dutch_word);
                                setSelectedVariantForEdit(
                                  varient.replacement_dutch_word
                                );
                              }}
                              className="cursor-pointer w-[14px] h-[14px]"
                            />
                          </div>
                        </>
                      ) : (
                        <div
                          style={{ border: "1px solid #BFBFBF" }}
                          className="text-xs flex flex-col  bg-white pr-1"
                        >
                          <input
                            type="text"
                            maxLength={100}
                            placeholder="Type here..."
                            className="border-none text-xs  bg-transparent w-full py-1 px-2 outline-none"
                            value={inputVariant}
                            onChange={(e) => {
                              setInputVariant(e.target.value);
                            }}
                          />
                          <div className="flex w-full gap-2 justify-end">
                            <CloseIcon
                              onClick={() => {
                                setShowVariantInputFeild(false);
                                setInputVariant(false);
                                setSelectedVariantForEdit("");
                              }}
                              className="cursor-pointer w-[18px]  h-[18px] text-[#FF3B30]"
                            />
                            <DoneIcon
                              onClick={() => {
                                handleUpdateVariant(
                                  varient.replacement_dutch_word
                                );
                              }}
                              style={{
                                color: "#52C41A",
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {showVariantInputFeild && !selectedVariantForEdit && (
                  <div
                    style={{ border: "1px solid #BFBFBF" }}
                    className="text-xs flex flex-col  bg-white pr-1"
                  >
                    <input
                      type="text"
                      maxLength={100}
                      placeholder="Type here..."
                      className="border-none text-xs  bg-transparent w-full py-1 px-2 outline-none"
                      value={inputVariant}
                      onChange={(e) => {
                        setInputVariant(e.target.value);
                      }}
                    />
                    <div className="flex w-full gap-2 justify-end">
                      <CloseIcon
                        onClick={() => {
                          setShowVariantInputFeild(false);
                          setInputVariant(false);
                          setSelectedVariantForEdit("");
                        }}
                        className="cursor-pointer w-[18px]  h-[18px] text-[#FF3B30]"
                      />
                      <DoneIcon
                        onClick={addDutchVariants}
                        style={{
                          color: "#52C41A",
                          cursor: "pointer",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    </div>
                  </div>
                )}
                  <div
                    onClick={() => {
                      if(dutchVarients.length>=5){
                        toast("All variants are populated. Please remove one to add a new variant.", {
                          type: "warning",
                          autoClose: 3000,
                        });
                        return ;
                      }
                      setShowVariantInputFeild(true);
                      setInputVariant("");
                      setSelectedVariantForEdit("");
                    }}
                    className="py-2 h-full text-[10px] text-[#1E1C80] bg-[#F1F3F4] px-2 cursor-pointer flex items-center justify-center gap-1"
                  >
                    {!dutchVarients.length ? "Add Variant" : "Add More Variants"}
                    <AddGlossaryIcon />
                  </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-1 w-1/2">                
                <div
                  style={{ border: "1px solid #BFBFBF" }}
                  className="w-full text-xs flex flex-col  bg-white pr-1"
                >
                  <input
                    type="text"
                    maxLength={100}
                    autoComplete="off"
                    placeholder="Enter english word / sentence here..."
                    className="border-none text-xs bg-transparent w-full py-1 px-2 outline-none"
                    value={englishWordInputFeild}
                    onChange={handleInputChange}
                  />
                  <div className="flex w-full justify-end">
                    <DoneIcon
                      onClick={() => {
                        const isCurrentGlossaryExists = glossaryData.find(
                          (glossary) => glossary?.english_word?.toLowerCase() === englishWordInputFeild?.trim()?.toLowerCase()
                        );
                        if(isCurrentGlossaryExists){
                          toast(`English sentence / word already exists`, {
                            type: "warning",
                            autoClose: 3000,
                          });
                          return ;
                        }
                        setEnglishWord(englishWordInputFeild.trim())
                      }}
                      style={{
                        color: "#52C41A",
                        cursor: "pointer",
                        width: "18px",
                        height: "18px",
                        visibility: englishWordInputFeild ? "visible" : "hidden"
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2" />
            </>
          )}
        </div>
        <div
          style={{ borderTop: "1px solid #3B37FF" }}
          className="footer py-2 flex justify-end right-0 mx-2 gap-4  absolute bottom-0 w-[98%] "
        >
          <Button
            onClick={() => {
              if (isLoading) return;
              onClose();
            }}
            className="rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddToTheGlossary}
            variant="contained"
            className=" rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{ width: "24px", height: "24px" }}
                className="text-white mx-14 w-6 h-6"
              />
            ) : (
              <div className="flex items-center">
                Add to the glossary <ChevronRight />
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GlossaryModal;
