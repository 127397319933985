import React, { useCallback, useEffect, useRef } from "react";
import "./TranslateEditor.scss";
import "../../Pages/ReviewTranslation/ReviewTranslationPage.scss";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import WordCounter from "../WordCounter/WordCounter";
import store from "../../App/store";
import { ReactComponent as BackArrowIcon } from "../../assets/backArrow.svg";
import { ReactComponent as NextArrowIcon } from "../../assets/nextArrow.svg";
import { createRoot } from "react-dom/client";
import {
  handleAddNewParagraph,
  getItemFromCache,
} from "../../context/cache.service";
import {
  mouseoverSentence,
  mouseOutEventListner,
  replaceOverBook,
  translateChapter,
  updateUserActiveTime,
  searchTextOverBook,
  getNextChapterNumber,
  updateTotalMatchesOverBookElement,
  createTotalMatchesOverBookElement,
  resetTotalMatchesElement,
  scrollToHighlightedText,
  replaceOverBookButtonQuery,
  removeButton,
} from "../../utils/translationUtil";
import { loggedInUserActivity } from "../../utils/firebaseActions";
import debounce from "lodash.debounce";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FIND_AND_REPLACE_INTERACTIONS } from "../../utils/fireStoreTypes";
import { setFindAndReplaceWordMatchesCount } from "../../App/editor/editor.actions";
import { mostUsedTextIndent } from "../BookEditor/BookEditor";
import ChapterTranslationStatusBox from "../ChaptersTranslationStatusBox/ChapterTranslationStatusBox";
let mostUsedFontSize = "14px";

function TranslateEditor({
  translatedChapterInfo,
  setAfterHandledUnsavedChangesAction,
  handleChapterChange,
  editorConfig,
  translatedContentData,
  translateWordCountData,
  currentChapter,
  setTranslateEditor,
  setIsTranslatedEditorReady,
  translateEditor,
  toggleDialogBox,
  chaptersTranslationStatusList,
  setChaptersTranslationStatusList,
  isLayoutFixed,
  handleFindAndReplaceWordOverBook,
  handleNextButtonClick,
  handlePrevButtonClick,
  findOverBookData

}) {
  const params = useParams();
  const dispatch = useDispatch();
  const { loggedInUserEmail, userOpenedBookInfo } = useSelector(
    (state) => state.main
  );
  const {
    data: chapter,
    isPending: fetchTranslatedChapterPending,
    isError: fetchTranslatedChapterError,
    isSuccess: fetchTranslatedChapterSuccess,
  } = translatedChapterInfo;

 

 /**
  * Feature : Tab to indent
  * handleKeyPressIndentation function executes indentation on key press
  * it triggers indentation on TAB key and outdention on SHIFT + TAB key
  * @param {*} indentType :"indent" | "outdent"
  * @param {*} editor : translate Editor
  * @param {*} stop : stop event 
  */
  const handleKeyPressIndentation = (indentType, editor, stop) => {
    const selection = editor.model.document.selection;
    const selectedPElement = Array.from(selection.getSelectedBlocks())[0];

    const htmlPAttributes = selectedPElement?.hasAttribute("htmlPAttributes")
      ? { ...selectedPElement.getAttribute("htmlPAttributes") }
      : {};
    let isTextIndentApplied = false;
    if (htmlPAttributes) {
      if (!htmlPAttributes.styles) htmlPAttributes.styles = {};
      // Get the htmlPAttributes from the selectedPElement
      if (
        indentType === "indent" &&
        (!htmlPAttributes?.styles["text-indent"] ||
          htmlPAttributes?.styles["text-indent"] === "0px")
      ) {
        htmlPAttributes.styles[
          "text-indent"
        ] = `${mostUsedTextIndent.offset}${mostUsedTextIndent.unit}`;
        isTextIndentApplied = true;
      }

      if (
        indentType === "outdent" &&
        htmlPAttributes?.styles["text-indent"] !== "0px"
      ) {
        htmlPAttributes.styles["text-indent"] = "0px";
        isTextIndentApplied = true;
      }

      // Update the selectedPElement's htmlPAttributes attribute
      if (isTextIndentApplied) {
        editor.model.change((writer) => {
          writer.removeAttribute("htmlPAttributes", selectedPElement);
          writer.setAttribute(
            "htmlPAttributes",
            htmlPAttributes,
            selectedPElement
          );
        });
      }
    }

    if (!isTextIndentApplied) {
      editor.execute(indentType);
      isTextIndentApplied = false;
    }
    stop();
  };

  /**
   * The function `handleTranslatedEditorReady` initializes a CKEditor instance, listens for the
   * "enter" key press event, and sets the default font size for selected blocks while also appending
   * the editor's toolbar to a specific element.
   * @param editor - The `handleTranslatedEditorReady` function takes an `editor` parameter, which is
   * likely an instance of a text editor like CKEditor. This function sets up event listeners and
   * functionality for the editor when it is ready for use. The `editor` parameter is used to access
   * commands, selection, and
   */
  const handleTranslatedEditorReady = (editor) => {
    // Adding custom button in Find and replace Dialog to call
    //API to replace a word throughout the entire book.
    const findAndReplaceUiPlugin = editor.plugins.get("FindAndReplaceUI");
    const findAndReplaceEditing = editor.plugins.get("FindAndReplaceEditing");
    const dialogPlugin = editor.plugins.get("Dialog");
    const checkBookOverCountEleQuery =
      ".ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .translatecountBookOver";

    //event Triggeres when search and replace dialog  open
    dialogPlugin.on("show", (evt, data) => {
      const findAndReplaceDialogue = document.querySelector(
        '[aria-label="Find and replace"]'
      );
      findAndReplaceDialogue.style.opacity="0";
      setTimeout(()=>{
        dialogPlugin.view.moveTo(500,200);
        findAndReplaceDialogue.style.opacity="1";

      },100)
      updateUserActiveTime(params.id, loggedInUserEmail);
      loggedInUserActivity({ activity: FIND_AND_REPLACE_INTERACTIONS });
      dispatch(setFindAndReplaceWordMatchesCount(0));
      const { formView: findAndReplaceDialogFormView } = findAndReplaceUiPlugin;
      //flag to check if already there button or not (for second time when user opens)
      const isButtonAlreadyThere = document.querySelector(
        replaceOverBookButtonQuery
      );
      if (!isButtonAlreadyThere) {
        //creating replace over book button
        const replaceOverBookButton = document.createElement("button");
        replaceOverBookButton.innerText = "Replace over book";
        replaceOverBookButton.classList.add("replaceOverBookButton");

        //Adding Onclick event for calling API when user clicks on button
        replaceOverBookButton.addEventListener("click", (evt) => {
          // check is textToFind exist in our content or not
          const { _textToFind, _textToReplace, _matchCase, _wholeWordsOnly } =
            findAndReplaceDialogFormView;
          if (!_textToFind) return;

          //collecting user input data to send in API payload

          if (!_textToReplace) {
            replaceOverBookButton.blur();
            const t = findAndReplaceDialogFormView.t;
            findAndReplaceDialogFormView._replaceInputView.errorText = t(
              "Text to replace must not be empty for replace over book."
            );
            return;
          }

          replaceOverBook(
            params.id,
            _textToFind,
            _textToReplace,
            `${_matchCase}`,
            `${_wholeWordsOnly}`
          );

          // updating changes in UI by executing replaceAll
          const state = findAndReplaceEditing?.state;
          if (state?.searchText !== _textToFind) {
            findAndReplaceEditing?.find(_textToFind);
          }
          editor.execute(
            "replaceAll",
            _textToReplace,
            state?.results || _textToFind
          );
          if (!findAndReplaceDialogFormView?.matchCount) {
            replaceOverBookButton?.classList?.remove("enableHover");
            replaceOverBookButton.style.opacity = "0.5";
            resetTotalMatchesElement(
              'translate',
              handleNextButtonClick,
              handlePrevButtonClick,
              0
            );
            dispatch(setFindAndReplaceWordMatchesCount("N/A"));
            findOverBookData.current = {};
          }
          replaceOverBookButton.blur();
        });

        //putting our custom button into search and replace dialog
        document
          .querySelector(".ck .ck-find-and-replace-form__actions")
          ?.prepend(replaceOverBookButton);
      } else {
        isButtonAlreadyThere.style.opacity = "0.5";
        isButtonAlreadyThere?.classList?.remove("enableHover");
      }
      
      handleFindAndReplaceWordOverBook(findAndReplaceDialogFormView,checkBookOverCountEleQuery,"dutch")
    }
    );
    
    setTranslateEditor(editor);

    

    //Indent on pressing tab key
    editor.keystrokes.set("tab", (data, stop) => {
      // editor.execute("indent");
      handleKeyPressIndentation("indent", editor, stop);
      // stop();
    });

    //Remove Indent on pressing shift+tab key
    editor.keystrokes.set("Shift+Tab", (data, stop) => {
      // editor.execute("outdent");
      handleKeyPressIndentation("outdent", editor, stop);
      // stop();
    });

    // CKEditorInspector.attach(editor);
    const enter = editor.commands.get("enter");
    enter.on("afterExecute", (evt) => {
      const res = Array.from(
        editor.model.document.selection.getSelectedBlocks()
      );

      //setting most used font size as default font size
      if (res[0]?._attrs?.get("htmlPAttributes")) {
        const htmlPAttributes = {
          ...res[0]._attrs.get("htmlPAttributes"),
        };
        htmlPAttributes.styles["font-size"] = mostUsedFontSize;
        res[0]._attrs.set("htmlPAttributes", htmlPAttributes);
      }
      // @deepesh commenting this so that original paragraphs style are copied to the new paragraph
      // res[0]._attrs = new Map();
    });
    setIsTranslatedEditorReady(true);
    const toolbarElement = editor.ui.view.toolbar.element;
    const buttons = toolbarElement.querySelectorAll('.ck-button');
    let findAndReplaceButton=null;
    buttons.forEach(button => {
      const label = button.querySelector('.ck-button__label');
      console.log("debugging content",label?.textContent,label,label?.innerText)
      if (label && label.textContent.trim() === 'Find and replace') {
        findAndReplaceButton=button
      }
    });
    //const findAndReplaceButton = toolbarElement.querySelector('[data-cke-tooltip-text="Find and replace (Ctrl+F)"]');
    if (findAndReplaceButton) {
      findAndReplaceButton?.setAttribute(
        "data-cke-tooltip-text",
        "Find and replace"
      );
      removeButton(
        toolbarElement,
        '[data-cke-tooltip-text="Find and replace (Ctrl+F)"]'
      );
      const translateToolBarContainer = document.querySelector(
        ".translateFindAndReplaceButton"
      );
      translateToolBarContainer.append(findAndReplaceButton);
    }
   
    toolbarElement
      ?.querySelector(".ck .ck-font-size-dropdown .ck-dropdown__button")
      ?.setAttribute("data-cke-tooltip-text", "Font size");
    toolbarElement
      ?.querySelector(".ck .ck-font-family-dropdown .ck-dropdown__button")
      ?.setAttribute("data-cke-tooltip-text", "Font family");
    document
      ?.querySelector("#translation-editor-toolbar")
      ?.appendChild(toolbarElement);
 
       // To move the src-find tool to the translated toolbar
    // const toolBarButtons = toolbarElement?.querySelectorAll('.ck-button');
    // const srcFindButton = document.getElementsByClassName('sourceToolBar')[0]?.querySelector('.ck-button');
    // const separatorNode = document.createElement('span');
    // separatorNode.className="ck ck-toolbar__separator";
    // const parent = toolBarButtons[3]?.parentNode;

    // parent?.insertBefore(srcFindButton, toolBarButtons[3]);
    // parent?.insertBefore(separatorNode, toolBarButtons[3]);

    const instantLearningSwitch = document.querySelector(
      ".ck .ck-toolbar__items .ck-switchbutton"
    );
    instantLearningSwitch.classList.add("ck-disabled");
  };

  return (
    <div id="translated" className="">
      {!fetchTranslatedChapterPending &&
        !translatedContentData &&
        chaptersTranslationStatusList &&
        chaptersTranslationStatusList[currentChapter]?.status !==
          "translated" && (
          <ChapterTranslationStatusBox
            currentChapter={currentChapter}
            chaptersTranslationStatusList={chaptersTranslationStatusList}
            translationId={params.id}
            translateChapter={translateChapter}
            translateEditor={translateEditor}
            setChaptersTranslationStatusList={setChaptersTranslationStatusList}
            toggleDialogBox={toggleDialogBox}
            handleChapterChange={handleChapterChange}
          />
        )}

      <CKEditor
        editor={Editor}
        config={editorConfig}
        data={translatedContentData?.length > 0 ? translatedContentData : ""}
        onChange={(event, editor) => {
          const allTranslatedParagraphs = document.querySelectorAll(
            `#translated .ck-editor__editable > p`
          );

          if (isLayoutFixed) {
            const mouseOverEventListner = (event) => {
              event.stopPropagation();
              const { target } = event;
              mouseoverSentence(
                target,
                chapter?.chapter_data?.English,
                handleAddNewParagraph,
                getItemFromCache,
                currentChapter,
                params.id
              );
            };

            allTranslatedParagraphs.forEach((paragraph) => {
              if (!paragraph.eventListeners) {
                if (!paragraph.eventListeners) {
                  paragraph.eventListeners = {};
                }
                if (!paragraph.eventListeners["mouseenter"]) {
                  paragraph.eventListeners["mouseenter"] = [];
                }
                paragraph.eventListeners["mouseenter"].push(
                  mouseOverEventListner
                );

                paragraph.addEventListener("mouseenter", mouseOverEventListner);

                paragraph.addEventListener("mouseleave", mouseOutEventListner);
              }
            });
          }
          const data = editor.getData();
        }}
        onReady={handleTranslatedEditorReady}
        onFocus={(event) => {
          const element = document.getElementsByClassName(
            "ck-sticky-panel__content_sticky"
          );
          if (element[0]) {
            element[0].style.top = "6.5rem";
          }
        }}
      />
      <WordCounter
        bookWords={translateWordCountData.bookWords}
        chapterWords={translateWordCountData.chapterWords}
        currentChapterNo={currentChapter}
        fetchTranslatedChapterPending={fetchTranslatedChapterPending}
        editorType={"translated"}
        bookWordsDifference={translateWordCountData.bookWordsDifference}
        chapterWordsDifference={translateWordCountData.chapterWordsDifference}
        fetchTranslatedChapterError={fetchTranslatedChapterError}
        isAllChaptersNotTranslated={translateWordCountData.isAllChaptersNotTranslated}
        chapter={chapter}
      />
    </div>
  );
}

export default TranslateEditor;
