
import { Plugin } from "@ckeditor/ckeditor5-core";
import { SwitchButtonView } from "@ckeditor/ckeditor5-ui";



class ToggleInstantLearning extends Plugin {
  isToggled: boolean = true
  init() {
    const editor = this.editor;
    const toggleInstantLearning = (editor.config.get("toggleInstantLearning") || {} as any)
      ?.toggleInstantLearning as any;
    editor.ui.componentFactory.add("toggleInstantLearningButton", (locale) => {
      const switchButton = new SwitchButtonView(locale);

      switchButton.set({
        label: "RLEF",
        withText: true,
        isOn: this.isToggled,
        tooltip:this.isToggled?"Switch this OFF to restrict the AI to learn from your edits!":"Switch this ON for the AI to learn from your edits!"
      });


      // Execute a callback function when the button is clicked.
      switchButton.on("execute", () => {
        this.isToggled = !this.isToggled
        switchButton.set({
          label: "RLEF",
          withText: true,
          isOn: this.isToggled,
          tooltip:this.isToggled?"Switch this OFF to restrict the AI to learn from your edits!":"Switch this ON for the AI to learn from your edits!"
        });
        toggleInstantLearning(editor, this.isToggled);
      });

      return switchButton;
    });
  }
}

export default ToggleInstantLearning