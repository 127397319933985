import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const styles = {
  backdrop: {
    '--mui-custom-palette-neutral-darkChannel': '50 56 62',
    '--mui-custom-palette-background-backdrop': 'rgba(var(--mui-custom-palette-neutral-darkChannel, 251 252 254) / 0.25)',
    backgroundColor:'var(--mui-custom-palette-background-backdrop)',
    "-webkitTapHighlightColor": "transparent",
    backdropFilter: "blur(8px)",
  },
  dialogPaper: {
    border: "1px solid #3A37FF",
  },
  dialogContent: {
    px: '60px',
    pt: '10px',
    pb: '60px',
  },
  dialogTitle: {
    py: '30px',
    pt: '30px',
    px: '60px',
    fontFamily: 'Poppins',
    color: '#1E1C80',
    fontSize: '30px',
    fontWeight: 100,
    textWrap: 'wrap',
    textAlign: 'center',
    position: 'relative',
    lineHeight: '1',
  }
};

export default function EmailModal({title="test",showEmailModal,setShowEmailModal,email,setEmail,submitEmailAndCallApi}) {

  const [error, setError] = React.useState(false)

  const handleDialogClose=()=>{
    setShowEmailModal(false);
  }
  

  React.useEffect(()=>{
  },[email])

  return (
    <Dialog
    slotProps={{backdrop:{sx:styles.backdrop}}}
    PaperProps={{sx:styles.dialogPaper}}
    maxWidth="md"
    fullWidth
      open={showEmailModal}
      onClose={handleDialogClose}
    >
      <DialogTitle sx={styles.dialogTitle}>
        {title}
        <div className="absolute top-0 right-0">
          <IconButton onClick={handleDialogClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <div className="pb-4">
          <Typography component="h2" color="primary.dark" className='text-center' >
            The Book has been uploaded & we are translating your book.
          </Typography>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!email || !validateEmail(email)) {
              setError(true);
              return;
            }
            setShowEmailModal(false);
            setError(false);
          }}
        >
          <Stack spacing={2}>
            <TextField
                variant='outlined'
                size='small'
                onBlur={() => !email && setError("")}
                placeholder="Enter Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && (
              <small style={{ color: "red" }}>
                Please enter a valid email.
              </small>
            )}
            <Button
              variant="contained"
              disableElevation
              onClick={!validateEmail(email) ? () => {} : submitEmailAndCallApi}
              type="submit"
              className='text-[16px] sm:text-[20px]'
            >
              Email me when translated
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}