import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { styled, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserNameFromEmail } from "../../utils/translationUtil";
import { setGlossarySuggestionsData } from "../../App/editor/editor.actions";
import { useParams } from "react-router-dom";
import { applySuggestedGlossary } from "../../utils/glossaryUtils";
import store from "../../App/store";
import { toast } from "react-toastify";


function GlossarySuggestion({setAfterHandledUnsavedChangesAction ,applyGlossary, chapter,glossarySuggestionData,fetchGlossarySuggestionsData }) {
  const params=useParams()
  const dispatch=useDispatch();
  const {currentChapter}=useSelector((state)=>state.editor)
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const handleSuggestionAction = async (actionType, suggestedGlossary) => {
   
    if(store.getState().editor.isGlossaryApplying){
      toast(`Please wait for the previous glossary process to complete.`, {
        type: "warning",
        autoClose: 3000,
      });
      return ; 
    } 

    const payload = {
      object_id: suggestedGlossary.object_id,
      updated_status: actionType,
      priority_chapter_number: currentChapter,
    };
    
    const isTranslatedContentEdited =
    store?.getState()?.editor?.isTranslationContentEdited;
    if(isTranslatedContentEdited && actionType==="Applied"){
      setAfterHandledUnsavedChangesAction({
        actionName: "applySuggestedGlossary",
        actionData: { applyPayload:payload, applyType:"glossarySuggestion",chapterData:chapter},
      });
       return ;
    }


    const updatedSuggestedGlossaries = glossarySuggestionData.filter(
      (suggestion) => suggestion.object_id !== suggestedGlossary.object_id
    );
     dispatch(setGlossarySuggestionsData(updatedSuggestedGlossaries));
     fetchGlossarySuggestionsData(params.id,updatedSuggestedGlossaries)

  
   
    if(actionType==="Rejected"){
      await applySuggestedGlossary(payload)
      return;
    } 

    
    await applyGlossary(payload, "glossarySuggestion",chapter);
  };

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 130px)" }}
      className=" overflow-y-scroll flex flex-col hideSourceScrollBar"
    >
      {glossarySuggestionData.length ? glossarySuggestionData?.map((suggestedGlossary) => {
        return (
          <div
            key={suggestedGlossary.object_id}
            className="flex items-start pb-2 gap-5 w-full"
          >
            <div className="py-2 pr-2 flex-col flex justify-between text-xs w-1/2 h-full bg-[#F1F3F4] pl-4">
              {suggestedGlossary.english_word}
              <div className="flex items-end  justify-end gap-2">
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black", // Change to your desired color
                      color: "white", // Optional: Change text color
                    },
                  }}
                  placement="top-end"
                  arrow
                  title={getUserNameFromEmail(suggestedGlossary.applied_by)}
                >
                  <GlossaryInfoIcon className="cursor-pointer w-[14px] h-[14px]" />
                </CustomTooltip>
              </div>
            </div>

            <div className="py-2 w-1/2  px-2 text-xs flex gap-2 justify-between flex-col  bg-[#F1F3F4] ">
              <div className="flex items-center gap-2">
                {
                  suggestedGlossary.dutch_word_array[
                    suggestedGlossary.applied_index
                  ].replacement_dutch_word
                }
              </div>
              <div className="flex items-end  justify-end gap-3">
                <div
                  onClick={() =>
                    handleSuggestionAction("Rejected", suggestedGlossary)
                  }
                  className="flex items-center cursor-pointer gap-2"
                >
                  <span className="text-[#CF1322] text-[12px]">Discard</span>
                  <CloseIcon className="cursor-pointer w-[16px]  h-[16px] text-[#CF1322]" />
                </div>
                <div
                  onClick={() =>
                    handleSuggestionAction("Applied", suggestedGlossary)
                  }
                  className="flex items-center cursor-pointer gap-2"
                >
                  <span className="text-[#389E0D] text-[12px]">Apply</span>
                  <DoneIcon
                    style={{
                      color: "#389E0D",
                      cursor: "pointer",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }) : <div className="w-full text-center text-sm  pt-10">
              No suggestions exists
        </div>
        }
    </div>
  );
}

export default GlossarySuggestion;
