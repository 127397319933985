import React, { useEffect, useState ,useCallback } from "react";
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from "react-redux";

import Button  from "@mui/material/Button";
import IconButton  from "@mui/material/IconButton";
import Tooltip  from "@mui/material/Tooltip";

import useApi from "../../api/useAPi";
import Card from "../../Components/Card/Card";
import Table from "../../Components/Table/Table";

import "./Dashboard.scss";

import linebr from "../../assets/linebr.svg";
import optmor from "../../assets/optmor.svg";
import user from "../../assets/contactIcon.svg";
import { ReactComponent as UploadIcon } from './../../assets/uploadIcon.svg';
import apiUrls from "../../api/apiUrl";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { fetchBooksList, setConcurrentEditsData, setTransformedData, setUserOpenedBookInfo } from "../../App/DashboardActions/action";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Footer from "../../Components/Footer/Footer";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useStatus from "../../hooks/useStatus";
import { CONCURRENT_EDITS_TRACKER } from "../../utils/fireStoreTypes";

const Dashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const myParam = searchParams.get('test');
  const location = useLocation();
  const [translating,setTranslatingData] = useState([])
  const dispatch = useDispatch();
  const { fetchData } = useApi();
  const { isDutchUploadInput } = location.state || {};
  const type = isDutchUploadInput ? "Pairing" : "Translation";
  const [restrictedBookData, setRestrictedBookData] = useState([])
  const [tableData, setTableData] = useState([]);
  const isSearchVisible=["qa","dev"].includes(process.env.REACT_APP_ENVIRONMENT);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDataFoundInSearch, setIsDataFoundInSearch] = useState(true)

  const statusHook = useStatus()
  const userId = localStorage.getItem('userId')
  useEffect(() => {
    statusHook.checkStatus(userId)
  },[userId])

  
  const { isLoading, data, isItemLoadingId } = useSelector((state) => state.main);

  useEffect(() => {
        if(data.length === 0){
          getBooksList("")
        }else{
          getBooksList(isItemLoadingId)
        }
     dispatch(setUserOpenedBookInfo({}))
  }, []);


  const getBooksList = (isItemCallId) => {
    dispatch(fetchBooksList({ fetchData ,isItemCallId}));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const collectionRef = collection(firestore, "hc_book_translation");
      const concurrentEditsTrackerRef = collection(firestore, CONCURRENT_EDITS_TRACKER);

      const concurrentEditsUnsubscribe = onSnapshot(concurrentEditsTrackerRef, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let restrictedBookList = [];
        if (process.env.REACT_APP_ENVIRONMENT === "qa") {
          restrictedBookList = newData.filter((item) => item.id === "qa_data")?.[0];
        } 
        else if(process.env.REACT_APP_ENVIRONMENT === "dev"){
          restrictedBookList = newData.filter((item) => item.id === "dev_data")?.[0];
        }
        else if(process.env.REACT_APP_ENVIRONMENT === "prod") {
          restrictedBookList = newData.filter((item) => item.id === "prod_data")?.[0];
        }
        setRestrictedBookData(restrictedBookList || [])
        dispatch(setConcurrentEditsData(restrictedBookList || []))
      })
      
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let translatedData = [];
        if (process.env.REACT_APP_ENVIRONMENT === "qa") {
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_qa")?.[0];
        } 
        else if(process.env.REACT_APP_ENVIRONMENT === "dev"){
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_dev")?.[0];
        }
        else {
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_prod")?.[0];
        }

        console.log("newDatashika", newData);
        console.log("translatedData", translatedData);

        const transformedData = [];
        const getStatusLabel = (status) => {
          if (status === "Completed") return "Pairing Completed";
          if (status === "Queued") return "Queued For Translation";
          if (status === "Translating") return "Translation in Progress";
          if (status === "Translated") return "AI Translation Completed";
          return status;
        };
        const order = { Translated: "", Translating: "", Queued: "" };
        if(translating?.length===0){
          setTranslatingData(translatedData["Translating"])
        }
        for (const key in order) {
          if (translatedData?.hasOwnProperty(key)) {
            translatedData?.[key].forEach((tag) => {
              transformedData.push({
                title: tag.book_title,
                author: tag.author_name,
                status: getStatusLabel(key),
                pages: "NA",
                link: tag.unique_string,
                created_at_date: tag.created_at_date,
                created_at_time: tag.created_at_time,
                temperature: tag.temperature,
                assistant_name: tag.assistant_name
              });
            });
          }
        }
        //console.log("getting data",,newData[1])
        //setData(newData);
        dispatch(setTransformedData(transformedData));
      });

      return () =>{ 
        unsubscribe()
        concurrentEditsUnsubscribe()
      };
    };

    fetchData();
  }, []);



  useEffect(()=>{
    if(translating?.[0]?.unique_string  === data?.[0]?.link){
      if(data?.[0]?.status==="AI Translation Completed"){
        toast.success('AI translation completed successfully!')
      }
    }
  },[data?.[0]?.status, translating])

 

  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove all cookies 
    // @todo 
    // removeCookie('__Secure-next-auth.session-token');
    // console.log(cookies, 'cookies');
    // window.location.reload();
    // Redirect to the desired URL after logout
    // window.location.assign(process.env.REACT_APP_AUTH_URL_LOGOUT);
    window.location.href = 'https://appsecauth-ai.techo.camp/cross-signout?redirect_url=' + window.location.href;

  };
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Update filteredData whenever data changes
    setFilteredData(data);
  }, [data]);

 const debouncedSearch = useCallback(debounce((term) => {
  if (term && data.length > 0) {
    const lowercasedSearchTerm = term.toLowerCase();
    const newFilteredData = data.filter(item =>
      item.title.toLowerCase().includes(lowercasedSearchTerm) ||
      item.author.toLowerCase().includes(lowercasedSearchTerm) ||
      item.status.toLowerCase().includes(lowercasedSearchTerm)
    );
    if(newFilteredData.length>0){
      setIsDataFoundInSearch(true)
    }else{
      setIsDataFoundInSearch(false)
    }
    setFilteredData(newFilteredData);
  } else {
    setFilteredData(data);
  }

  if(!term)
    setIsDataFoundInSearch(true)
}, 500), [data]);

const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearchTerm(value);
  debouncedSearch(value);
};
  
  return (
    <div className="w-screen h-screen overflow-hidden">
      <header className="dashboard-header w-full bg-white md:p-4 flex flex-col md:flex-row md:items-center gap-2 fixed top-0 left-0 z-10">
        <div className="dashboard-title">HC Book Translation Assistant</div>
        <div className="md:flex md:items-center gap-4">
          {/* <img src={user} alt="user" /> */}
          {/* <img src={linebr} alt="user" /> */}
          {/* <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { } }}
              sx={{
                '&:hover': {
                  backgroundColor: '#aeaeae', // Set the hover background to transparent
                },
              }}
            >
              <MoreVert sx={{color: 'white'}}/>
            </MenuButton>
            <Menu>
              <MenuItem onClick={handleLogout}>Logout <ExitToAppIcon fontSize="10px"/></MenuItem>
            </Menu>
          </Dropdown> */}
          {isSearchVisible && (
            <input
              type="text"
              placeholder="Search..."
              className="search-input-dashboard focus:outline-none"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          )}
          <Button
            size="large"
            className="py-3"
            variant="outlined"
            disableElevation
            onClick={() => navigate("/upload-books")}
          >
            <div className="flex gap-2">
              <UploadIcon/>
                Upload Books
            </div>
          </Button>
          <Tooltip title="Sign out">
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon className="text-[#1E1C80]" />
            </IconButton>
          </Tooltip>

          {/* <img src={optmor} alt="user" onClick={handleLogout} className="optmor"/> */}
        </div>
      </header>
      {/* <div className="dashboard-sub-header">
        <div className="card-container">
          <Card title="Total Books" value={data?.length} /> */}
      {/* <Card
            title="Pairing Done"
            value={data.filter((item) => item.status === "Pairing Completed").length}
          /> */}
      {/* <Card
            title="Queued"
            value={data.filter((item) => item.status === "Queued For Translation").length}
          />
          <Card
            title="Translating"
            value={data.filter((item) => item.status === "Translation in Progress").length}
          />
          <Card
            title="Translated"
            value={data.filter((item) => item.status === "AI Translation Completed").length}
          /> */}
      {/* <Card title="Reviewed" value="0" disabled={true} />
          <Card title="In Review" value="0" disabled={true} />
          <Card title="Pending" value="0" disabled={true} /> */}
      {/* </div> */}
      {/* <div className="txt-btn-container"> */}
      {/* <input
            type="text"
            placeholder="Type text here"
            className="search-input-dashboard"
          /> */}

      {/* </div> */}
      {/* </div> */}
      <Table
        page={page}
        rowsPerPage={rowsPerPage}
        data={filteredData}
        restrictedBookData={restrictedBookData}
        fetchBooksList={() => {}}
        isLoading={isLoading}
        isDataFoundInSearch={isDataFoundInSearch}
      />
      <Footer 
       data={filteredData}
       setRowsPerPage={setRowsPerPage}
       page={page}
       rowsPerPage={rowsPerPage} 
       setPage={setPage}
       />
    </div>
  );
};

export default Dashboard;
